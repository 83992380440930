<script setup lang="ts">
interface Props {
  rows?: number
  columns?: number
  customColumnWidth?: { columnIndex: number; width: string }[]
}
const props = withDefaults(defineProps<Props>(), {
  rows: 4,
  columns: 4,
  customColumnWidth: () => [],
})

function getCellStyle(colIndex: number) {
  const column = props.customColumnWidth.find((s) => s.columnIndex === colIndex)
  return column?.width || ''
}
</script>

<template>
  <table class="w-full">
    <tr v-for="row in rows" :key="row">
      <td
        v-for="col in columns"
        :key="col"
        :style="{ width: getCellStyle(col) }"
        class="h-[3.75rem] border-y px-4"
      >
        <LoadersShimmerLoader class="h-7 w-full rounded-[0.25rem]" />
      </td>
    </tr>
  </table>
</template>
